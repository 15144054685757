.screen-center{
  position: absolute;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarImg{
  width: 50px;
  height: 50px;
}

/* TEMPORARY DARK MODE
*{
  background-color: hsl(0, 0%, 20%);
  color: white;
} */

/* COLORINGS */
/* .bg-dark{
  background-color: hsl(0, 0%, 20%);
  color: white;
} */

/* TYPOGRAPHY */
/* header{
  height: max(20vh, 90px);
}

main{
  min-height: 80vh;
}

.screen-center{
  position: absolute;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

.flex{
  display: flex;
}

.flex-center{
  justify-content: center;
}

.flex-column{
  flex-direction: column;
}

.text-center{
  text-align: center;
} */

/*  */
/* .p-1{
  padding: 4px;
}

.p-2{
  padding: 8px;
} */

/* IDs */
/* #errorBox{
  display: flex;
  justify-content: center;
  flex-direction: column;
} */